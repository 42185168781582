import config from '@/../public/assets/conf.json';
import axios from 'axios';


export async function saveWasteDelivery(ftForm: any, ptForm: any, moneyOutcome: boolean, wasteValue: any): Promise<any> { 
    try {
        const multiFinPost = await axios.post(''.concat(config['host_ip'], config['host_port'], "/transactions/multi_financial/"), ftForm)
        console.log(multiFinPost);
        const ftransactionIds = multiFinPost.data.ids

        const PhyPost = await axios.post(''.concat(config['host_ip'], config['host_port'], "/transactions/physical/"), ptForm)
        console.log(PhyPost);
        const ptransactionId = PhyPost.data.id
        const relations = ftransactionIds.map((el: any) => {
            return {
                'f_transaction': el,
                'p_transaction': ptransactionId
            }
        })

        const RelationsPost = await axios.post(''.concat(config['host_ip'], config['host_port'], "/transactions/relation/"), relations)
        console.log(RelationsPost);

        if(moneyOutcome) {
            ftForm['value'] = -1 * parseFloat(wasteValue) 
            ftForm['reason'] = 'Instant Withdrawal'
            ftForm['wastes'] = []
            const response = await axios.post(''.concat(config['host_ip'], config['host_port'], "/transactions/financial/"), ftForm)
            
            return response
        } else {
            return RelationsPost
        }
    } catch (error) { 
        return error.response
    }
}

export async function saveMoneyOutcome(ftForm: any): Promise<any> { 
    try {
        const FinPost = await axios.post(''.concat(config['host_ip'], config['host_port'], "/transactions/financial/"), ftForm)
        console.log(FinPost);
        return FinPost
    } catch (error) { 
        return error.response
    }
}

export async function saveFixStock(ptForm: any): Promise<any> { 
    try {
        const PhyPost = await axios.post(''.concat(config['host_ip'], config['host_port'], "/transactions/physical/"), ptForm)
        console.log(PhyPost);
        return PhyPost
    } catch (error) { 
        return error.response
    }
}

export async function updatePricing(priceForm: any): Promise<any> { 
    try {
        const PricesPost = await axios.post(''.concat(config['host_ip'], config['host_port'], "/pricing/"), priceForm)
        console.log(PricesPost);
        return PricesPost
    } catch (error) { 
        return error.response
    }
}

export async function saveUserProfile(profileForm: any): Promise<any> { 
    try {
        const ProfilePost = await axios.post(''.concat(config['host_ip'], config['host_port'], "/user-create/"), profileForm)
        console.log(ProfilePost);
        return ProfilePost
    } catch (error) { 
        return error.response
    }
}

export async function addUserRole(workerForm: any): Promise<any> { 
    try {
        const ProfilePost = await axios.post(''.concat(config['host_ip'], config['host_port'], "/workers/"), workerForm)
        console.log(ProfilePost);
        return ProfilePost
    } catch (error) { 
        return error.response
    }
}

export async function revokeUserRole(employeeId: any): Promise<any> { 
    try {
        const ProfilePost = await axios.delete(''.concat(config['host_ip'], config['host_port'], "/workers/", employeeId))
        console.log(ProfilePost);
    } catch (error) { 
        return error.response
    }
}

export async function updateUserRole(employeeId: any, workerForm: any): Promise<any> { 
    try {
        const deleteRole = await axios.delete(''.concat(config['host_ip'], config['host_port'], "/workers/", employeeId))
        
        console.log(deleteRole)
        const postRole = await addUserRole(workerForm)
        return postRole
    } catch (error) {
        return error.response
    }
}

export async function updateTransaction(transactionId: any, tForm: any): Promise<any> { 
    try {
        const resp = await axios.put(''.concat(config['host_ip'], config['host_port'], "/transactions/physical/detail/", transactionId.toString()), tForm)
        return resp
    } catch (error) {
        return error.response
    }
}

